import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useUsersStore } from "@/store/user"; // 导入你的 Pinia store
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jb from "@frontend/effuse";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/Download.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/index.vue"),
  },
  {
    path: "/pay-result",
    name: "PayResult",
    component: () =>
      import(
        /* webpackChunkName: "payResult" */ "../views/PayResult/index.vue"
      ),
  },
  {
    path: "/real-name-auth",
    name: "RealNameAuth",
    component: () =>
      import(
        /* webpackChunkName: "realNameAuth" */ "../views/RealNameAuth/index.vue"
      ),
  },
  {
    path: "/auction-field-list",
    name: "AuctionFieldList",
    component: () => import("../views/AuctionField/list/index.vue"),
  },
  {
    path: "/auction-field-tradeing",
    name: "AuctionFieldTradeing",
    component: () => import("../views/AuctionField/tradeing/index.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Test.vue"),
  },
  {
    path: "/subject",
    name: "subject",
    component: () =>
      import(/* webpackChunkName: "Subject" */ "../views/Subject/index.vue"),
  },
  {
    path: "/subject-bid-list",
    name: "subject-bid-list",
    component: () =>
      import(
        /* webpackChunkName: "Subject" */ "../views/SubjectBidList/index.vue"
      ),
  },
  {
    path: "/token-owner-list",
    name: "token-owner-list",
    meta: {
      useDefaultNav: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "TokenOwnerList" */ "../views/TokenOwnerList/index.vue"
      ),
  },
  {
    path: "/post-detail",
    name: "post-detail",
    meta: {
      useDefaultNav: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "PostDetail" */ "../views/PostDetail/index.vue"
      ),
  },
  {
    path: "/suspect-detail",
    name: "suspect-detail",
    meta: {
      useDefaultNav: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "SuspectDetail" */ "../views/SuspectDetail/index.vue"
      ),
  },
  {
    path: "/token-detail",
    name: "token-detail",
    meta: {
      useDefaultNav: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "TokenDetail" */ "../views/TokenDetail/index.vue"
      ),
  },
  {
    path: "/product-detail",
    name: "product-detail",
    meta: {
      useDefaultNav: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ProductDetail" */ "../views/ProductDetail/index.vue"
      ),
  },
  {
    path: "/c2c-lottery",
    name: "c2c-lottery",
    meta: {
      useDefaultNav: true,
      needLogin: true, // app环境 beforeEach时从app获取userInfo, 获取不到就跳转App登录页
      title: "抽选",
    },
    component: () =>
      import(
        /* webpackChunkName: "C2cLottery" */ "../views/C2cLottery/index.vue"
      ),
  },
  {
    path: "/share-prove",
    name: "share-prove",
    meta: {
      useDefaultNav: false,
      needLogin: true, // app环境 beforeEach时从app获取userInfo, 获取不到就跳转App登录页
      title: "分享证明",
    },
    component: () =>
      import(
        /* webpackChunkName: "ShareProve" */ "../views/ShareProve/index.vue"
      ),
  },
  {
    path: "/c2c-lottery-players",
    name: "c2c-lottery-players",
    meta: {
      useDefaultNav: true,
      needLogin: true,
      title: "全部参与者",
    },
    component: () =>
      import(
        /* webpackChunkName: "C2cLotteryPlayers" */ "../views/C2cLottery/players.vue"
      ),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Profile/index.vue"),
  },
  {
    path: "/oc-profile",
    name: "oc-profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/OcProfile/index.vue"),
  },
  {
    path: "/oc-profile-list",
    name: "oc-profile-list",
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "../views/OcProfileList/index.vue"
      ),
  },
  {
    path: "/wallet",
    name: "wallet",
    meta: {
      useDefaultNav: true,
    },
    component: () =>
      import(/* webpackChunkName: "Wallet" */ "../views/Wallet/index.vue"),
  },
  {
    path: "/:w+",
    name: "default",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/Download.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有保存的滚动位置，则返回它，否则滚动到页面顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

const syncGetUserInfo = (): Promise<string> => {
  console.log("syncGetUserInfo调用了");
  return new Promise((resolve, reject) => {
    jb.getUserInfo({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      success: (info: any) => {
        // 修改这里的类型为 any
        const tempInfo = JSON.parse(info || "{}");
        tempInfo.userId = tempInfo.id; // TODO 应该是app端转好
        resolve(tempInfo);
      },
      fail: () => {
        reject();
      },
    });
  });
};

// 在这里设置 afterEach 导航守卫
router.afterEach(async (to, from) => {
  const needLogin = to.meta.needLogin;
  const title = to.meta.title;
  const userStore = useUsersStore();

  if (title) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.title = title;
  }

  if (window.isInFlutter && needLogin) {
    try {
      const tempUserInfo = await syncGetUserInfo();
      console.log("tempUserInfo from app", tempUserInfo);
      userStore.setUserInfo(tempUserInfo); // 更新 Pinia store 中的 userInfo
    } catch (error) {
      console.error("Failed to get user info", error);
      // 处理错误，例如重定向到错误页面或登录页面
      jb.navigateTo({ url: `congress://user/login` });
    }
  }
});

export default router;
