import { rpApi } from "@/http";
import { isProd } from "@/utils/env-related";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { genOSSUpload } from "@frontend/eros/dist/web";

export async function ossUpload({
  file,
  scene = "user-post-v2.image",
}: {
  file: File;
  scene?: string;
}) {
  console.log("genOSSUpload", file, scene);

  const upload = genOSSUpload({
    isProd,
    async customApiCall(data: any) {
      const res: any = await rpApi.post(
        "/uploader/gene-post-object-sign",
        data
      );
      return res.data?.[0];
    },
  });

  return upload({ file, scene });
}
